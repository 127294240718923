import { Product } from './product';

export class ShoppingCart {

    public dateCreated: Date;
    public items: ShoppingCartItem[] = [];
    public totalPrice?: number;
    public _id?: string;
    public currency?: string;

    constructor() { }
}

export class ShoppingCartItem {

    public product: Product;
    public quantity: number = 0;
    public isLoading?: boolean = false;

    constructor() { }
}


