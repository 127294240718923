import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/services/order/order.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.css']
})
export class SuccessPaymentComponent implements OnInit {

  public dataIsReady: boolean = false;
  public order: any;

  constructor(private route: ActivatedRoute,
    private toastrService: ToastrService,
    private orderService: OrderService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let token = params['Token'];

      this.orderService.getSaleDetails(token)
        .subscribe(
          (order: any) => {
            console.log(order);
            this.order = order;
            this.dataIsReady = true;
          },
          (error: string) => {
            this.toastrService.error("Cannot get order details", 'Error', { closeButton: true });
          });

    });
  }

}
