import { ShoppingCart } from './../../../models/shopping-cart';
import { VisitorsService } from './../../../services/visitor/visitor.service';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/models/product';
import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input('product') product: Product;
  @Input('shopping-cart') shoppingCart: ShoppingCart;

  public visitor: any;
  public isLoading: boolean = false;

  constructor(private cartService: ShoppingCartService,
    private toastrService: ToastrService,
    private visitorsService: VisitorsService) { }

  ngOnInit() {
    this.visitor = this.visitorsService.getVisitor();
  }

  public addToCart() {
    this.isLoading = true;

    this.cartService.addProductToShoppingCart(this.product._id)
      .then((shoppingCart: ShoppingCart) => {
        this.isLoading = false;
        this.shoppingCart = shoppingCart;
        this.cartService.setCart(shoppingCart);
        this.cartService.sendMessageShoppingCartChanged(this.shoppingCart);
        this.toastrService.success('Successfullly added product.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastrService.error("Cannot add item to cart", 'Error', { closeButton: true });
      });
  }

  public removeFromCart() {
    this.isLoading = true;

    this.cartService.removeProductToShoppingCart(this.product._id)
      .then((shoppingCart: ShoppingCart) => {
        this.isLoading = false;
        this.shoppingCart = shoppingCart;
        this.cartService.setCart(shoppingCart);
        this.cartService.sendMessageShoppingCartChanged(this.shoppingCart);
        this.toastrService.success('Successfullly removed product.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastrService.error("Cannot remove item from cart", 'Error', { closeButton: true });
      });

  }

  public getQuantity() {
    if (!this.shoppingCart) return 0;

    for (const item of this.shoppingCart.items) {

      if (item.product._id === this.product._id)
        return item.quantity;
    }

    return 0;

  }
}
