import { DownloadService } from './../../../services/download/download.service';
import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  inProgress: boolean = false;
  percent: number;

  constructor(private downloadService: DownloadService) { }

  ngOnInit() {
  }

  download(fileName: string) {
    this.inProgress = true;
    this.downloadService.downloadProgram(fileName)
      .subscribe(
        response => {
          if (response.type === HttpEventType.DownloadProgress) {
            this.percent = Math.round(100 * response.loaded / response.total);
            console.log( this.percent);
          }
          if (response.type === HttpEventType.Response) {

            setTimeout(() => {
              this.percent = 0;
              this.inProgress = false;
            }, 800);

            const blob: Blob = new Blob([response.body], { type: response.body.type });
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);

          }
        },
        (error: any) => {
          this.percent = 0;
          this.inProgress = false;
        })
  }

}
