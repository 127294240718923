import { Product } from 'src/app/models/product';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private apiName = "products";
  private products: Product[] = [];

  constructor(private dataService: DataService) { }

  public getProducts(): Observable<Product[]> {
    this.dataService.apiName = this.apiName;
    return this.dataService.getData();
  }

  public getProduct(productId: string): Observable<Product> {
    this.dataService.apiName = this.apiName;
    return this.dataService.getDataByParam(productId);
  }

  public addProduct(product: Product): Observable<Product> {
    this.dataService.apiName = this.apiName;
    return this.dataService.add(product);
  }

  public updateProduct(product: Product): Observable<Product> {
    this.dataService.apiName = this.apiName;
    return this.dataService.update(product._id, product);
  }

  public uploadProductImage(fd: FormData): Observable<Product> {
    this.dataService.apiName = this.apiName + '/upload-image';
    return this.dataService.add(fd);
  }

  public setProducts(products: Product[]) {
    this.products = products;
  }

  public getLocalProducts() {
    return this.products;
  }

  public getLocalProductsByProtocolCategroy(category: string) {

    let products = [];
    
    for (const product of this.products) {
      for (const c of product.categories) {
        if (c === category) {
          products.push(product);
          break;
        }
      }
    }

    return products;
  }

}