import { ShoppingCart } from 'src/app/models/shopping-cart';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private cart: ShoppingCart;

  private shoppingCartSubject = new Subject<any>();
  private test = new Subject<any>();
  private apiName = "shopping-carts";

  constructor(private dataService: DataService) { }

  public async getShoppingCart(): Promise<ShoppingCart> {
    var cartId = await this.getOrCreateShoppingCartId();
    this.dataService.apiName = this.apiName;
    let shoppingCart = await this.dataService.getDataByParam(cartId).toPromise();

    if (shoppingCart.new) {
      localStorage.removeItem('cartId');
      localStorage.setItem('cartId', shoppingCart._id);
    }

    return shoppingCart;
  }

  public getShoppingCarts(): Observable<ShoppingCart[]> {
    this.dataService.apiName = this.apiName;
    return this.dataService.getData();
  }

  private createCart(): Promise<ShoppingCart> {
    this.dataService.apiName = this.apiName;
    var date = new Date();
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let dateCreated = new Date(date.getTime() - userTimezoneOffset);
    return this.dataService.add({ dateCreated: dateCreated }).toPromise();
  }

  public async getOrCreateShoppingCartId(): Promise<string> {

    let cartId = localStorage.getItem('cartId');
    if (cartId) return cartId;

    // if the cart does not exist so add her to db and save it in local storage
    let shoppingCart = await this.createCart();
    localStorage.setItem('cartId', shoppingCart._id);
    return shoppingCart._id;
  }

  // check if the product already exist in the cart so update the quantity
  // if not exit so add him to items array and set the quantity to 1
  public async addProductToShoppingCart(productId: string): Promise<ShoppingCart> {
    let cartId = await this.getOrCreateShoppingCartId();
    this.dataService.apiName = this.apiName;
    return this.dataService.update(cartId, { productId: productId }).toPromise();
  }

  public async removeProductToShoppingCart(productId: string): Promise<ShoppingCart> {
    let cartId = await this.getOrCreateShoppingCartId();
    this.dataService.apiName = this.apiName + '/remove';
    return this.dataService.update(cartId, { productId: productId }).toPromise();
  }

  public async clearShoppingCart(): Promise<ShoppingCart> {
    let cartId = await this.getOrCreateShoppingCartId();
    this.dataService.apiName = this.apiName + '/clear';
    return this.dataService.getDataByParam(cartId).toPromise();
  }

  public getCart() {
    return this.cart;
  }

  public setCart(cart) {
    this.cart = cart;
  }

  public sendMessageShoppingCartChanged(data: any) {
    this.shoppingCartSubject.next(data);
  }

  public getMessageShoppingCartChanged(): Observable<any> {
    return this.shoppingCartSubject.asObservable();
  }

  public sendMessageCartIsReady(data: boolean) {
    this.test.next(data);
  }

  public getMessageCartIsReady(): Observable<any> {
    return this.test.asObservable();
  }

}
