import { VisitorsService } from '../services/visitor/visitor.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyConverter'
})
export class CurrencyConverterPipe implements PipeTransform {

  constructor(public visitorsService: VisitorsService,
    private currencyPipe: CurrencyPipe, ) {
  }

  transform(value: any, args?: any): any {
    let visitor = this.visitorsService.getVisitor();
    let x = "US";
    if (visitor) {

      //switch (visitor.countryCode) {
      switch (x) {
        // Shekel
        case "IL":
          return this.currencyPipe.transform(value, 'ILS');

        // Euro
        case "AT":
        case "AD":
        case "BE":
        case "CY":
        case "EE":
        case "FI":
        case "FR":
        case "DE":
        case "GR":
        case "IE":
        case "IT":
        case "LV":
        case "LT":
        case "LU":
        case "MT":
        case "YT":
        case "MC":
        case "ME":
        case "NL":
        case "PT":
        case "RE":
        case "PM":
        case "BL":
        case "MF":
        case "SM":
        case "SK":
        case "ES":
        case "CH":
        case "CH":
        case "CH":
        case "CH":
        case "CH":
          return this.currencyPipe.transform(value * 0.259061, 'EUR');
        // US dollar
        case "AS":
        case "IO":
        case "EC":
        case "SV":
        case "GU":
        case "HT":
        case "MH":
        case "FM":
        case "MP":
        case "PW":
        case "PA":
        case "PR":
        case "TC":
        case "US":
          return this.currencyPipe.transform(value * 0.288652, 'USD');

        // Russian Rubale
        case "RU":
          return this.currencyPipe.transform(value * 17.6692, 'RUB');

        // Yuan Renminbi
        case "CN":
          return this.currencyPipe.transform(value * 1.98958, 'CNY');

        // US Dollar
        default:
          return this.currencyPipe.transform(value * 0.288652, 'USD');
      }
    }
    // US Dollar
    else
      return this.currencyPipe.transform(value * 0.288652, 'USD');
  }

}
