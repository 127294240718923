import { ProductService } from 'src/app/services/product/product.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ShoppingCart } from 'src/app/models/shopping-cart';

@Component({
  selector: 'app-bacnet',
  templateUrl: './bacnet.component.html',
  styleUrls: ['./bacnet.component.css']
})
export class BacnetComponent implements OnInit {
public products: Product[] = [];
  public errorMsg: string;
  public shoppingCart: ShoppingCart;

  constructor(private productService: ProductService,
    private shoppingCartService: ShoppingCartService) {

    this.shoppingCartService.getMessageCartIsReady()
      .subscribe((data) => {
        this.shoppingCart = this.shoppingCartService.getCart();
      })
  }

  ngOnInit() {
    this.shoppingCart = this.shoppingCartService.getCart();
    this.products = this.productService.getLocalProductsByProtocolCategroy("Bacnet");
  }
}
