import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  public apiName: string = "downloads";

  constructor(private dataService: DataService) { }

  public downloadProgram(fileName: string): Observable<any> {
    this.dataService.apiName = this.apiName + '/programs';
    return this.dataService.getFile(fileName);
  }
  public downloadVideo(fileName: string): Observable<any> {
    this.dataService.apiName = this.apiName + '/videos';
    return this.dataService.getFile(fileName);
  }


}
