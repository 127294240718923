import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle("Lcontrol - About");
    
    this.metaTagService.updateTag(
      { name: 'description', content: 'About' }
    );

    this.metaTagService.updateTag(
      { name: 'keywords', content: 'About, Lcontrol, BMS, Building managment system, SNIPER' }
    );
  }

}
