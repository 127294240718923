import { Component, OnInit } from '@angular/core';
import { QrCodeService } from '../../services/qr-code/qr-code.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  public svg: string = "";
  public showError: boolean = false;

  constructor(private qrCodeService: QrCodeService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      const qrData = params['qrData'];
      this.createQrCode(qrData);
    });
  }

  ngOnInit() {
    $("accessibility").hide();
    $("translate").hide();
    $("contact-button").hide();
    $("app-top-navbar").hide();
  }

  private createQrCode(qrData: string) {
    this.qrCodeService.createQrCode(qrData).subscribe(
      (data: { svg: string }) => {
        this.svg = data.svg;
        $("#qr-code-svg").html(this.svg);
      }, (error: any) => {
        this.showError = true;
      })
  }

}
