import { OrderService } from './../../services/order/order.service';
import { ToastrService } from 'ngx-toastr';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-check-out',
  templateUrl: './success-check-out.component.html',
  styleUrls: ['./success-check-out.component.css']
})
export class SuccessCheckOutComponent implements OnInit {

  public dataIsReady: boolean = false;
  public order: any;

  constructor(private route: ActivatedRoute,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      let token = params['Token'];

      this.orderService.getSaleDetails(token)
        .subscribe(
          (order: any) => {

            this.order = order;

            this.shoppingCartService.clearShoppingCart()
              .then((shoppingCart) => {
                this.shoppingCartService.setCart(shoppingCart);
                this.shoppingCartService.sendMessageShoppingCartChanged(shoppingCart);
                this.dataIsReady = true;
              })
              .catch((error) => {
                this.toastrService.error("Cannot clear shopping cart", 'Error', { closeButton: true });
              });
          },
          (error: string) => {
            this.toastrService.error("Cannot get order details", 'Error', { closeButton: true });
          });

    });
  }

}
