import { JwtService } from './../jwt/jwt.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor {

  constructor(private jwtService: JwtService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const token = this.jwtService.getToken();

    if (token && req.url !== "http://ip-api.com/json") {

      headersConfig['x-auth-token'] = token;

      const cloned = req.clone({
        setHeaders: headersConfig
      });

      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }

  }

}
