import { AddToCartButtonComponent } from './components/add-to-cart-button/add-to-cart-button.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeleteComponent } from './components/delete/delete.component';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BackHardwareButtonComponent } from './components/back-hardware-button/back-hardware-button.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgxPaginationModule
  ],
  exports: [
    FormsModule,
    CommonModule,
    SpinnerComponent,
    DeleteComponent,
    NgxPaginationModule,
    AddToCartButtonComponent,
    BackButtonComponent,
    BackHardwareButtonComponent
 ],
  declarations: [
    SpinnerComponent,
    DeleteComponent,
    AddToCartButtonComponent,
    BackButtonComponent,
    BackHardwareButtonComponent
  ]
})
export class SharedModule { }
