import { SideNavbarService } from './../services/side-navbar.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  constructor(private sideNavbarService: SideNavbarService) { }

  ngOnInit() { }

  public collaspeSidbar(category: string) {
    this.sideNavbarService.setCollapseIconToMinus(category);
    $('#' + category).collapse('show');
  }

}
