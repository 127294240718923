import { ToastrService } from 'ngx-toastr';
import { Credentials } from './../../models/credentials';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
//import { AuthService, SocialUser, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public credentials: Credentials = new Credentials();
  public isLoading: boolean = false;
  public inavlidEmailOrPassword: boolean = false;
  public returnUrl: string;

  constructor(private router: Router,
    //private googleAuthService: AuthService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private toastr: ToastrService) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/client';
  }

  public login() {

    this.isLoading = true;
    this.inavlidEmailOrPassword = false;

    this.authService.login(this.credentials)
      .subscribe(
        (result: any) => {

          if (result) {
            // login successful so redirect to return url
            if (this.authService.currentLoggedUserValue.isAdmin)
              this.router.navigateByUrl('admin');
            else
              this.router.navigateByUrl(this.returnUrl);
          }
          else {
            this.inavlidEmailOrPassword = true;
            this.isLoading = false;
          }
        },
        (error: string) => {
          console.log(error);

          if (error === "Invalid email or password")
            this.inavlidEmailOrPassword = true;
          else
            this.toastr.error("Error", 'Error', { closeButton: true });

          this.isLoading = false;
        })
  }

  // public loginWithGoogle(): void {

  //   this.isLoading = true;

  //   this.googleAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((response: SocialUser) => {

  //       this.authService.loginWithGoogleOrFb(response.email)
  //         .subscribe(
  //           (result: any) => {

  //             if (result) {
  //               // login successful so redirect to return url
  //               if (this.authService.currentLoggedUserValue.isAdmin)
  //                 this.router.navigateByUrl('admin');
  //               else
  //                 this.router.navigateByUrl(this.returnUrl);
  //             }
  //             else {
  //               this.inavlidEmailOrPassword = true;
  //               this.isLoading = false;
  //             }

  //           },
  //           (error: string) => {
  //             console.log(error);

  //             if (error === "Invalid email or password")
  //               this.inavlidEmailOrPassword = true;
  //             else
  //               this.toastr.error("Error", 'Error', { closeButton: true });

  //             this.isLoading = false;
  //           })
  //     })
  //     .catch((error) => {
  //       if (error != "User cancelled login or did not fully authorize.")
  //         this.toastr.error("error", "error", { closeButton: true });

  //       this.isLoading = false;

  //     });
  // }

  // public loginWithFb(): void {
  //   this.googleAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
  //     .then((response: SocialUser) => {

  //       this.authService.loginWithGoogleOrFb(response.email)
  //         .subscribe(
  //           (result: any) => {

  //             if (result) {
  //               // login successful so redirect to return url
  //               if (this.authService.currentLoggedUserValue.isAdmin)
  //                 this.router.navigateByUrl('admin');
  //               else
  //                 this.router.navigateByUrl(this.returnUrl);
  //             }
  //             else {
  //               this.inavlidEmailOrPassword = true;
  //               this.isLoading = false;
  //             }

  //           },
  //           (error: string) => {
  //             console.log(error);

  //             if (error === "Invalid email or password")
  //               this.inavlidEmailOrPassword = true;
  //             else
  //               this.toastr.error("Error", 'Error', { closeButton: true });

  //             this.isLoading = false;
  //           })

  //     })
  //     .catch((error) => {
  //       if (error != "User cancelled login or did not fully authorize.")
  //         this.toastr.error("error", "error", { closeButton: true });

  //       this.isLoading = false;

  //     });;
  // }

}