import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from 'src/app/services/data/data.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeleteModalProperties } from 'src/app/models/delete-modal-properties';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  @Output() onCloseModal = new EventEmitter<any>();
  @Input() properties: DeleteModalProperties;

  public isSaving: boolean = false;
  private modalClosed: boolean = false;

  constructor(private toastrService: ToastrService,
    private dataService: DataService) { }

  ngOnInit() {
    $('#deleteModal').modal('show');
  }

  ngOnDestroy() {
    // if the user pressed on back button
    if (!this.modalClosed)
      this.closeModal();
  }

  public closeModal(data?: any) {
    $('#deleteModal').modal('hide');
    this.modalClosed = true;
    this.onCloseModal.emit(data);
  }

  public onSubmit() {
    this.isSaving = true;

    this.dataService.apiName = this.properties.apiName;
    this.dataService.delete(this.properties.data['_id'])
      .subscribe(
        (response: any) => {
          this.closeModal(response);
          this.toastrService.success('Successfully deleted item.', 'Success', { closeButton: true });
        },
        (error: HttpErrorResponse) => {
          this.toastrService.error('Cannot delete item.', 'Error', { closeButton: true });
          this.isSaving = false;
        });
  }
}

