export class Signup {

    public email: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public country: string;
    public address: string;
    public zipCode: string;
    public companyName: string;
    public phoneNumber: string;
    
    constructor() {}
}
