import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {

  public hebrewArray = [
    {
      title: "RTLS",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/RTLS-hebrew-small.pdf"
    },
    {
      title: "PDF.Energy_Saving",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/energy-saving-hebrew-small.pdf"
    },
    {
      title: "PDF.Projects",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/--project-hebrew-small--.pdf"
    },
    {
      title: "PDF.Sniper",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/sniper-hebrew-small.pdf"
    },
    {
      title: "PDF.Sniper",
      type: "far fa-file-powerpoint",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/power-point/sniper-hebrew-pptx.pptx"
    },
    {
      title: "PDF.Work_Permits",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/work-permits-hebrew-small.pdf"
    },
    {
      title: "PDF.Event_Schedule",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/event-schedule-hebrew-small.pdf"
    },
    {
      title: "PDF.Parking_Management",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/parking-management-hebrow-small.pdf"
    },
    {
      title: "PDF.Cyber",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/cyber-hebrew-small.pdf"
    },
    {
      title: "PDF.Counter_Module",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/counter-hebrew-small.pdf"
    },
    {
      title: "PDF.Technological_status_assessment",
      type: "far fa-file-powerpoint",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/Technological-status-assessment.pptx"
    },
    {
      title: "PDF.Technological_status_assessment",
      type: "far fa-file-word",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/Technological-status-assessment-2.docx"
    },
  ]

  public englishArray = [
    {
      title: "PDF.Energy_Saving",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/energy-saving-english-small.pdf"
    },
    {
      title: "PDF.Projects",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/--project-english-small--.pdf"
    },
    {
      title: "PDF.Sniper",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/sniper-english-small.pdf"
    },
    {
      title: "PDF.Sniper",
      type: "far fa-file-powerpoint",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/power-point/sniper-english-pptx.pptx"
    },
    {
      title: "PDF.Work_Permits",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/work-permits-english-small.pdf"
    },
    {
      title: "PDF.Event_Schedule",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/event-schedule-english-small.pdf"
    },
    {
      title: "PDF.Parking_Management",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/parking-management-english-small.pdf"
    },
    {
      title: "PDF.Cyber",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/cyber-english-small.pdf"
    },
    {
      title: "PDF.Cyber-secomea",
      type: "far fa-file-powerpoint",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/power-point/cyber-secomea.pptx"
    },
    {
      title: "PDF.Counter_Module",
      type: "far fa-file-pdf",
      subTitle: "PDF.English",
      src: "../../../../assets/pdf/counter-english-small.pdf"
    },

  ]

  constructor() { }

  ngOnInit() {
  }

}
