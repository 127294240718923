import { DataService } from './../data/data.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {

  public visitor: any;
  public apiName: string = "locations";

  constructor(private http: HttpClient, private dataService:DataService) { }


  

  public getVisitorDetails(): Observable<any> {
    this.dataService.apiName = this.apiName;
    return this.dataService.getData();
    //return this.http.get('http://ip-api.com/json');
  }

  public setVisitorDetails(visitorDetails) {
    this.visitor = visitorDetails;
  }

  public getVisitor() {
    return this.visitor;
  }
}
