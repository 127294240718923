import { Covid19Component } from './components/projects/covid19/covid19.component';
import { SuccessPaymentComponent } from './core/success-payment/success-payment.component';
import { ModbusManagerComponent } from './components/products/hardware/modbus/modbus-manager/modbus-manager.component';
import { TelefireMainComponent } from './components/products/hardware/telefire/telefire-main/telefire-main.component';
import { OpcMainComponent } from './components/products/hardware/opc/opc-main/opc-main.component';
import { KnxMainComponent } from './components/products/hardware/knx/knx-main/knx-main.component';
import { HikvisionMainComponent } from './components/products/hardware/hikvision/hikvision-main/hikvision-main.component';
import { DaliMainComponent } from './components/products/hardware/dali/dali-main/dali-main.component';
import { SimplexMainComponent } from './components/products/hardware/simplex/simplex-main/simplex-main.component';
import { SnmpMainComponent } from './components/products/hardware/snmp/snmp-main/snmp-main.component';
import { CurrencyConverterPipe } from './pipes/currency-converter.pipe';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
//import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { TopNavbarComponent } from './core/top-navbar/top-navbar.component';
import { NotAuthorizedComponent } from './core/not-authorized/not-authorized.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { SignupComponent } from './core/signup/signup.component';
import { LoginComponent } from './core/login/login.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { FailedCheckOutComponent } from './core/failed-check-out/failed-check-out.component';
import { SuccessCheckOutComponent } from './core/success-check-out/success-check-out.component';
import { CategoriesComponent } from './components/products/hardware/categories/categories.component';
import { ShoppingCartComponent } from './core/shopping-cart/shopping-cart.component';
import { ProductCardComponent } from './components/products/product-card/product-card.component';
import { HomeComponent } from './core/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ProgramsComponent } from './components/downloads/programs/programs.component';
import { VideosComponent } from './components/Videos/videos/videos.component';
import { PdfComponent } from './components/downloads/pdf/pdf.component';
import { ControlComponent } from './components/cyber/control/control.component';
import { SecurityComponent } from './components/cyber/security/security.component';
import { SecomeaComponent } from './components/cyber/secomea/secomea.component';
import { SmartBuildingsComponent } from './components/projects/smart-buildings/smart-buildings.component';
import { GeneralProjectsComponent } from './components/projects/general-projects/general-projects.component';
import { HardwareComponent } from './components/products/hardware/hardware.component';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';
import { AccessibilityService } from './services/accessibility/accessibility.service';
import { TranslateComponent } from './core/translate/translate.component';
import { CookiesService } from './services/cookies/cookies.service';
import { SniperComponent } from './components/products/software/sniper/sniper.component';
import { EnergySavingComponent } from './components/products/software/energy-saving/energy-saving.component';
import { ParkingManagmentComponent } from './components/products/software/parking-managment/parking-managment.component';
import { TrucksManagmentComponent } from './components/products/software/trucks-managment/trucks-managment.component';
import { WorkPermitComponent } from './components/products/software/work-permit/work-permit.component';
import { EventScheduleComponent } from './components/products/software/event-schedule/event-schedule.component';
import { CounterComponent } from './components/products/software/counter/counter.component';
import { SoftwareComponent } from './components/products/software/software.component';
import { LocationStrategy, CurrencyPipe, PathLocationStrategy } from '@angular/common';
import { EnvServiceProvider } from './services/env/env.service.provider';
import { ToastrModule } from 'ngx-toastr';
import { SmartCityComponent } from './components/projects/smart-city/smart-city.component';
import { ContactButtonComponent } from './components/contact-button/contact-button.component';
import { RealTimeLocationComponent } from "./components/products/software/real-time-location/real-time-location.component";
import { LconModbBacnMs01Component } from './components/products/hardware/modbus/lcon-modb-bacn-ms01/lcon-modb-bacn-ms01.component';
import { ModbusMainComponent } from './components/products/hardware/modbus/modbus-main/modbus-main.component';
import { LconModbModbMs04Component } from './components/products/hardware/modbus/lcon-modb-modb-ms04/lcon-modb-modb-ms04.component';
import { LconModbOpccMs03Component } from './components/products/hardware/modbus/lcon-modb-opcc-ms03/lcon-modb-opcc-ms03.component';
import { LconModbSnmpMs02Component } from './components/products/hardware/modbus/lcon-modb-snmp-ms02/lcon-modb-snmp-ms02.component';
import { LconFirnBacnMs06Component } from './components/products/hardware/firenet/lcon-firn-bacn-ms06/lcon-firn-bacn-ms06.component';
import { FirenetMainComponent } from './components/products/hardware/firenet/firenet-main/firenet-main.component';
import { LconFirnModbMs05Component } from './components/products/hardware/firenet/lcon-firn-modb-ms05/lcon-firn-modb-ms05.component';
import { LconFirnOpccMs07Component } from './components/products/hardware/firenet/lcon-firn-opcc-ms07/lcon-firn-opcc-ms07.component';
import { LconFirnSnmpMs08Component } from './components/products/hardware/firenet/lcon-firn-snmp-ms08/lcon-firn-snmp-ms08.component';
import { LconSplxBacnMs10Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-bacn-ms10/lcon-splx-bacn-ms10.component';
import { LconSplxModbMs09Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-modb-ms09/lcon-splx-modb-ms09.component';
import { LconSplxOpccMs12Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-opcc-ms12/lcon-splx-opcc-ms12.component';
import { LconSplxSnmpMs11Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-snmp-ms11/lcon-splx-snmp-ms11.component';
import { LconTfireBacnMs14Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-bacn-ms14/lcon-tfire-bacn-ms14.component';
import { LconTfireModbMs13Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-modb-ms13/lcon-tfire-modb-ms13.component';
import { LconTfireOpccMs15Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-opcc-ms15/lcon-tfire-opcc-ms15.component';
import { LconTfireSnmpMs16Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-snmp-ms16/lcon-tfire-snmp-ms16.component';
import { LconHikvModbMs17Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-modb-ms17/lcon-hikv-modb-ms17.component';
import { LconHikvBacnMs18Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-bacn-ms18/lcon-hikv-bacn-ms18.component';
import { LconHikvModbMs19Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-modb-ms19/lcon-hikv-modb-ms19.component';
import { LconHikvBacnMs20Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-bacn-ms20/lcon-hikv-bacn-ms20.component';
import { BacnetComponent } from './components/products/hardware/bacnet/bacnet.component';
import { OperatingComponent } from './components/downloads/Operating/Operating.component';
import { TemperatureComponent } from './components/products/software/temperature/temperature.component';
import { LconTempModbMs25Component } from './components/products/hardware/modbus/lcon-temp-modb-ms25/lcon-temp-modb-ms25.component';
import { CentersComponent } from './components/products/Centers/Centers.component';
import { SniperEnterpriseComponent } from './components/products/sniper-enterprise/sniper-enterprise.component';
import { VideosTutorialsComponent } from './components/Videos/Videos-Tutorials/Videos-Tutorials.component';
import { QrCodeComponent } from './core/qr-code/qr-code.component';
import { LicensingAgreementComponent } from './components/products/licensing-agreement/licensing-agreement.component';

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.googleID)
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(environment.facebookID)
//   }
// ]);
// export function provideConfig() {
//   return config;
// }


@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    ProductCardComponent,
    ShoppingCartComponent,
    SuccessCheckOutComponent,
    FailedCheckOutComponent,
    SuccessPaymentComponent,
    CategoriesComponent,
    HomeComponent,
    AboutComponent,
    ProgramsComponent,
    VideosComponent,
    PdfComponent,
    ControlComponent,
    SecurityComponent,
    SecomeaComponent,
    SmartBuildingsComponent,
    GeneralProjectsComponent,
    Covid19Component,
    SniperComponent,
    EnergySavingComponent,
    ParkingManagmentComponent,
    TrucksManagmentComponent,
    WorkPermitComponent,
    EventScheduleComponent,
    CounterComponent,
    AccessibilityComponent,
    TranslateComponent,
    SoftwareComponent,
    SniperEnterpriseComponent,
    HardwareComponent,
    CentersComponent,
    EnergySavingComponent,
    ParkingManagmentComponent,
    TrucksManagmentComponent,
    WorkPermitComponent,
    EventScheduleComponent,
    CounterComponent,
    TemperatureComponent,
    SmartCityComponent,
    CurrencyConverterPipe,
    ContactButtonComponent,
    RealTimeLocationComponent,
    SnmpMainComponent,
    SimplexMainComponent,
    DaliMainComponent,
    HikvisionMainComponent,
    KnxMainComponent,
    OpcMainComponent,
    TelefireMainComponent,
    ModbusMainComponent,
    LconModbBacnMs01Component,
    LconModbModbMs04Component,
    LconModbOpccMs03Component,
    LconModbSnmpMs02Component,
    FirenetMainComponent,
    LconFirnBacnMs06Component,
    LconFirnModbMs05Component,
    LconFirnOpccMs07Component,
    LconFirnSnmpMs08Component,
    LconSplxBacnMs10Component,
    LconSplxModbMs09Component,
    LconSplxOpccMs12Component,
    LconSplxSnmpMs11Component,
    LconTfireBacnMs14Component,
    LconTfireModbMs13Component,
    LconTfireOpccMs15Component,
    LconTfireSnmpMs16Component,
    LconHikvModbMs17Component,
    LconHikvBacnMs18Component,
    LconHikvModbMs19Component,
    LconHikvBacnMs20Component,
    LconTempModbMs25Component,
    BacnetComponent,
    ModbusManagerComponent,
    OperatingComponent,
    VideosTutorialsComponent,
    QrCodeComponent,
    LicensingAgreementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    AngularFontAwesomeModule,
    HttpClientModule,
    //SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
  ],
  providers: [
    AccessibilityService,
    CookiesService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    //{ provide: AuthServiceConfig, useFactory: provideConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    EnvServiceProvider,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
