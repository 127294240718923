import { ShoppingCartService } from './../../../../../services/shopping-cart/shopping-cart.service';
import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product/product.service';
import { ShoppingCart } from 'src/app/models/shopping-cart';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-modbus-main',
  templateUrl: './modbus-main.component.html',
  styleUrls: ['./modbus-main.component.css']
})
export class ModbusMainComponent implements OnInit {

  public products: Product[] = [];
  public errorMsg: string;
  public shoppingCart: ShoppingCart;

  constructor(private productService: ProductService,
    private shoppingCartService: ShoppingCartService) {

    this.shoppingCartService.getMessageCartIsReady()
      .subscribe((data) => {
        this.shoppingCart = this.shoppingCartService.getCart();
      })

  }

  ngOnInit() {
    this.shoppingCart = this.shoppingCartService.getCart();
    this.products = this.productService.getLocalProductsByProtocolCategroy("Modbus");
  }
}
