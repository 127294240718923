import { ToastrService } from 'ngx-toastr';
import { EnvService } from './../../services/env/env.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Contact } from '../../models/contact';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public isSendingEmail: boolean = false;
  public contact: Contact = new Contact();
  public safeURL: any;

  constructor(private sanitizer: DomSanitizer,
    private http: HttpClient,
    private toastrService: ToastrService,
    private envService: EnvService) {

    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/GvBw9-XsAoY?autoplay=1&playlist=GvBw9-XsAoY&loop=1&rel=0');

    // change the css of the top nabvar when we on home route
    $("#top-navbar-home").addClass("home-page");
  }

  ngOnInit() {

  }


  ngOnDestroy() {
    $("#top-navbar-home").removeClass("home-page");
  }

  ngAfterViewInit() {

    // Add smooth scrolling to all links in navbar + footer link
    $("footer a[href='#sniperSite']").on('click', function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 900);
      } // End if


    });

    $(window).scroll(function () {
      $(".slideanim").each(function () {
        var pos = $(this).offset().top;

        var winTop = $(window).scrollTop();
        if (pos < winTop + 600) {
          $(this).addClass("slide");
        }
      });
    });
  }

  public scroll(id: string) {
    let el = document.getElementById(id);
    if (el)
      el.scrollIntoView({ behavior: "smooth" });
  }

  public onSubmit() {

    this.isSendingEmail = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    };

    let cartId = localStorage.getItem('cartId');

    if (cartId)
      this.contact.shoppingCart = cartId;

    this.http.post(`${this.envService.apiUrl}/api/contacts`, JSON.stringify(this.contact), httpOptions)
      .subscribe(
        (response: any) => {
          this.toastrService.success('Successfullly send data, we will be in touch shortly.', 'Success', { closeButton: true, timeOut: 20000 });
          this.isSendingEmail = false;
        },
        (error: HttpErrorResponse) => {
          this.toastrService.error('Error occured.', 'Error', { closeButton: true, timeOut: 20000 });
          this.isSendingEmail = false;
        });

  }

}
