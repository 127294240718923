import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-firn-modb-ms05',
  templateUrl: './lcon-firn-modb-ms05.component.html',
  styleUrls: ['./lcon-firn-modb-ms05.component.css']
})
export class LconFirnModbMs05Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
