import { Component, OnInit } from '@angular/core';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';

@Component({
  selector: 'accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.css']
})
export class AccessibilityComponent implements OnInit {

  constructor(public accessibilityService: AccessibilityService) { }

  ngOnInit() {
  }

}
