import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-hikv-modb-ms17',
  templateUrl: './lcon-hikv-modb-ms17.component.html',
  styleUrls: ['./lcon-hikv-modb-ms17.component.css']
})
export class LconHikvModbMs17Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
