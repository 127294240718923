import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modbus-manager',
  templateUrl: './modbus-manager.component.html',
  styleUrls: ['./modbus-manager.component.css']
})
export class ModbusManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}