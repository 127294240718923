import { ShoppingCart } from './../../models/shopping-cart';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private apiName = "orders";

  constructor(private dataService: DataService) { }

  public getOrders(): Observable<any[]> {
    this.dataService.apiName = this.apiName;
    return this.dataService.getData();
  }

  public getUserOrders(): Observable<any[]> {
    this.dataService.apiName = this.apiName + '/user';
    return this.dataService.getData();
  }

  public checkOut(shoppingCart: any): Observable<any> {
    this.dataService.apiName = this.apiName + '/check-out';
    return this.dataService.getDataByObject(shoppingCart);
  }

  public getPaymentUrl(data: any): Observable<any> {
    this.dataService.apiName = this.apiName + '/payment-url';
    return this.dataService.getDataByObject(data);
  }

  public getSaleDetails(token: string): Observable<any> {
    this.dataService.apiName = this.apiName + '/sale-details';
    return this.dataService.getDataByParam(token);
  }

}
