import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-firn-snmp-ms08',
  templateUrl: './lcon-firn-snmp-ms08.component.html',
  styleUrls: ['./lcon-firn-snmp-ms08.component.css']
})
export class LconFirnSnmpMs08Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
