import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavbarService {

  public modbusCollapsed: boolean = false;
  public firenetCollapsed: boolean = false;
  public bacnetCollapsed: boolean = false;
  public simplexCollapsed: boolean = false;
  public telefireCollapsed: boolean = false;
  public hikvisionCollapsed: boolean = false;
  public snmpCollapsed: boolean = false;
  public opcCollapsed: boolean = false;
  public daliCollapsed: boolean = false;
  public knxCollapsed: boolean = false;

  constructor() { }

  public changeCollapseIcon(id: string) {

    switch (id) {
      case 'modbus':
        this.modbusCollapsed = !this.modbusCollapsed;
        break;
      case 'firenet':
        this.firenetCollapsed = !this.firenetCollapsed;
        break;
      case 'bacnet':
        this.bacnetCollapsed = !this.bacnetCollapsed;
        break;
      case 'simplex':
        this.simplexCollapsed = !this.simplexCollapsed;
        break;
      case 'telefire':
        this.telefireCollapsed = !this.telefireCollapsed;
        break;
      case 'hikvision':
        this.hikvisionCollapsed = !this.hikvisionCollapsed;
        break;
      case 'snmp':
        this.snmpCollapsed = !this.snmpCollapsed;
        break;
      case 'opc':
        this.opcCollapsed = !this.opcCollapsed;
        break;
      case 'dali':
        this.daliCollapsed = !this.daliCollapsed;
        break;
      case 'knx':
        this.knxCollapsed = !this.knxCollapsed;
        break;
      default:
        break;
    }
  }

  public setCollapseIconToMinus(id: string) {

    switch (id) {
      case 'modbus':
        this.modbusCollapsed = true;
        break;
      case 'firenet':
        this.firenetCollapsed = true;
        break;
      case 'bacnet':
        this.bacnetCollapsed = true;
        break;
      case 'simplex':
        this.simplexCollapsed = true;
        break;
      case 'telefire':
        this.telefireCollapsed = true
        break;
      case 'hikvision':
        this.hikvisionCollapsed = true
        break;
      case 'snmp':
        this.snmpCollapsed = true;
        break;
      case 'opc':
        this.opcCollapsed = true;
        break;
        case 'dali':
        this.daliCollapsed = true;
        break;
      case 'knx':
        this.knxCollapsed =true;
        break;
      default:
        break;
    }
  }

  public setAllIconsToPlus() {
    this.modbusCollapsed = false;
    this.firenetCollapsed = false;
    this.bacnetCollapsed = false;
    this.simplexCollapsed = false;
    this.telefireCollapsed = false;
    this.hikvisionCollapsed = false;
    this.snmpCollapsed = false;
    this.opcCollapsed = false;
    this.knxCollapsed = false;
    this.daliCollapsed = false;
  }

}

