import { ShoppingCartItem } from './../../models/shopping-cart';
import { OrderService } from 'src/app/services/order/order.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from './../../models/product';
import { ShoppingCart } from 'src/app/models/shopping-cart';
import { ShoppingCartService } from './../../services/shopping-cart/shopping-cart.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  public shoppingCart: ShoppingCart;
  public totalItemQuantity: number;
  public dataIsReady: boolean = false;
  public isRederecting: boolean = false;
  public isClearing: boolean = false;

  constructor(private shoppingCartService: ShoppingCartService,
    private orderService: OrderService,
    private router: Router,
    private authService: AuthenticationService,
    private toastrService: ToastrService) {

    this.shoppingCartService.getMessageCartIsReady()
      .subscribe((data) => {
        this.shoppingCart = this.shoppingCartService.getCart();
        this.totalItemQuantity = 0;

        //this.shoppingCart.totalPrice = this.totalPrice();
        for (let item of this.shoppingCart.items)
          this.totalItemQuantity += item.quantity;

        this.dataIsReady = true;
      })
  }

  ngOnInit() {

    this.shoppingCart = this.shoppingCartService.getCart();
    if (this.shoppingCart) {

      this.totalItemQuantity = 0;
      //this.shoppingCart.totalPrice = this.totalPrice();

      for (let item of this.shoppingCart.items)
        this.totalItemQuantity += item.quantity;

      this.dataIsReady = true;
    }
  }

  // private totalPrice() {
  //   let sum = 0;

  //   for (let item of this.shoppingCart.items)
  //     sum += item.quantity * item.product.price;

  //   return sum;
  // }

  public addToCart(product: Product, item: ShoppingCartItem) {

    item.isLoading = true;

    this.shoppingCartService.addProductToShoppingCart(product._id)
      .then((shoppingCart) => {

        item.isLoading = false;
        this.totalItemQuantity = 0;
        this.shoppingCart = shoppingCart;
        this.shoppingCartService.setCart(shoppingCart);
        //this.shoppingCart.totalPrice = this.totalPrice();

        for (let item of this.shoppingCart.items)
          this.totalItemQuantity += item.quantity;

        this.shoppingCartService.sendMessageShoppingCartChanged(this.shoppingCart);
        this.toastrService.success('Successfullly added product.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.toastrService.error("Cannot add item to cart", 'Error', { closeButton: true });
      });
  }

  public removeFromCart(product: Product, item: ShoppingCartItem) {

    item.isLoading = true;

    this.shoppingCartService.removeProductToShoppingCart(product._id)
      .then((shoppingCart) => {

        item.isLoading = false;
        this.totalItemQuantity = 0;
        this.shoppingCart = shoppingCart;
        this.shoppingCartService.setCart(shoppingCart);
        //this.shoppingCart.totalPrice = this.totalPrice();

        for (let item of this.shoppingCart.items)
          this.totalItemQuantity += item.quantity;

        this.shoppingCartService.sendMessageShoppingCartChanged(this.shoppingCart);
        this.toastrService.success('Successfullly removed product.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.toastrService.error("Cannot remove item from cart", 'Error', { closeButton: true });
      });
  }

  public clearCart() {
    this.isClearing = true;

    this.shoppingCartService.clearShoppingCart()
      .then((shoppingCart) => {

        this.isClearing = false;
        this.totalItemQuantity = 0;
        this.shoppingCart = shoppingCart;
        this.shoppingCartService.setCart(shoppingCart);
        //this.shoppingCart.totalPrice = this.totalPrice();

        for (let item of this.shoppingCart.items)
          this.totalItemQuantity += item.quantity;

        this.shoppingCartService.sendMessageShoppingCartChanged(this.shoppingCart);
        this.toastrService.success('Successfullly cleared shopping cart.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.isClearing = false;
        this.toastrService.error("Cannot clear shopping cart", 'Error', { closeButton: true });
      });
  }

  public checkOut() {

    this.dataIsReady = false;
    this.isRederecting = true;

    // only if the user logged in
    if (this.authService.currentLoggedUserValue) {

      let copyShoppingCart = JSON.parse(JSON.stringify(this.shoppingCart));
      // remove the image from the products for not loading the request 
      copyShoppingCart.items.filter((item) => delete item.product.image.image);

      this.orderService.checkOut(copyShoppingCart)
        .subscribe(
          (response: any) => {
            window.location.href = response.URL;
          },
          (error: string) => {
            this.toastrService.error("Cannot clear shopping cart", 'Error', { closeButton: true });
            this.dataIsReady = true;
          });
    }
    else
      this.router.navigate(['login']);

  }

  public navigateToContact() {
    $(document).ready(() => {
      let elmnt = document.getElementById("contact-us");
      elmnt.scrollIntoView({ behavior: 'smooth' });
    })
  }

}
