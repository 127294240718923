import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-firn-opcc-ms07',
  templateUrl: './lcon-firn-opcc-ms07.component.html',
  styleUrls: ['./lcon-firn-opcc-ms07.component.css']
})
export class LconFirnOpccMs07Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
