import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
  }
  public back() {
    this.location.back();
  }
}
