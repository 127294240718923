import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-event-schedule',
  templateUrl: './event-schedule.component.html',
  styleUrls: ['./event-schedule.component.css']
})
export class EventScheduleComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
  }
  public back() {
    this.location.back();
  }
}
