import { SideNavbarService } from './services/side-navbar.service';
import { Product } from './../../../models/product';
import { ProductService } from './../../../services/product/product.service';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.css']
})
export class HardwareComponent implements OnInit {

  public dataIsReady: boolean = false;
  public errorMsg: string;
  public products: Product[] = [];

  constructor(private productService: ProductService,
    private titleService: Title,
    private metaTagService: Meta,
    public sideNavbarService: SideNavbarService) {

      this.titleService.setTitle("Lcontrol - Products");
      
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Products, Modbus converter, Firenet converter, converter, Bacnet converter, Hardware, Telefire, SNMP, OPC, OPCUA, Dali converter, Hikvision, KNX, Price, Buy communication converter, Communication converter, Modbus communication' }
      );

  }

  ngOnInit() {

    this.productService.getProducts()
      .subscribe(
        (products: Product[]) => {
          this.products = products;
          this.productService.setProducts(products);
          this.dataIsReady = true;
        },
        (error: HttpErrorResponse) => {
          this.errorMsg = "There was an error, please try again later.";
        });


    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });

    $('.divParent').mouseover(function (e) {
      $(e.target).addClass('hover-parent')
    }).mouseout(function (e) {
      $(e.target).removeClass('hover-parent')
    })

    $('.divChild').mouseover(function (e) {
      e.stopPropagation();
      $(e.target).addClass('hover-child')
    }).mouseout(function (e) {
      $(e.target).removeClass('hover-child')
    })

  }

  ngOnDestroy(): void {
    this.sideNavbarService.setAllIconsToPlus();
  }

  public pressed(e, id: string) {
    if (e.target.tagName === 'BUTTON' || e.target.tagName == 'I') {
      e.preventDefault();
      e.stopPropagation();
      $('#' + id).collapse('toggle');
      let state = $('#' + id + '-collapse-btn').attr("aria-expanded");
      this.changeCollapseIcon(id);
    }
  }

  public pressedFromCard(id: string) {
    this.changeCollapseIcon(id);
  }

  public changeCollapseIcon(id: string) {
    this.sideNavbarService.changeCollapseIcon(id);
  }

}
