import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookiesService } from 'src/app/services/cookies/cookies.service';
declare var $: any;

@Component({
  selector: 'translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.css']
})
export class TranslateComponent implements OnInit {

  public flagsImages = {
    en: "../../../../assets/image/flags/usa-good.png",
    heb: "../../../../assets/image/flags/israel-good.png"
  }

  public currentLang: string;

  private toggleTranslateMenu: boolean = false;

  constructor(private translateService: TranslateService,
    private cookiesService: CookiesService) { }

  ngOnInit() {
    this.setUserLanguage();
  }

  private setUserLanguage() {
    this.currentLang = this.cookiesService.getCookie('lang');

    if (!this.currentLang) {
      this.currentLang = "en";
    }
  }

  // change the language and save it cookies and change the language button at the topnavbar menu
  public switchLanguage(language: string) {

    // change the lang of the website
    this.translateService.use(language);

    // save the lang in cookies
    this.cookiesService.setCookie('lang', language, 1);

    let headId = document.getElementById('rtlStyleSheet');

    //switch between rtl to ltr 
    if (language == "heb") {
      $("body").removeClass("ltr");
      $("body").addClass("rtl");
      headId.setAttribute('href', '../../assets/bootstrap-rtl.min.css');
    }
    else {
      $("body").removeClass("rtl");
      $("body").addClass("ltr");
      headId.removeAttribute('href');
    }
    this.currentLang = language;
    this.toggleTranslateMenu = true;
    this.openOrCloseTranslateMenu();
  }

  public openOrCloseTranslateMenu() {


    if (!this.toggleTranslateMenu) {
      if (this.currentLang == "he")
        $(".choose-lang").css({ "left": "-4px" });
      else
        $(".choose-lang").css({ "right": "-4px" });
    }
    else {
      if (this.currentLang == "he")
        $(".choose-lang").css({ "left": "-250px" });
      else
        $(".choose-lang").css({ "right": "-250px" });
    }

    this.toggleTranslateMenu = !this.toggleTranslateMenu;
  }

}
