import { EnvService } from '../../services/env/env.service';
import { Component, OnInit, enableProdMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public email: string;
  public isLoading: boolean = false;
  public emailNotExist: boolean = false;
  public emailSent: boolean = false;

  constructor(private http: HttpClient,
    private envService: EnvService,
    private toastr: ToastrService) { }

  ngOnInit() {}

  public onSubmit() {

    this.isLoading = true;
    this.emailNotExist = false;
    this.emailSent = false;

    this.http.post<any>(`${this.envService.apiUrl}/api/auth/forgot-password`, { email: this.email })
      .subscribe(
        (result: any) => {
          this.isLoading = false;
          this.emailSent = true;
          this.toastr.success(result.message, 'Success', { closeButton: true });
        },
        (error: string) => {
          if (error === "No such user with the given email")
            this.emailNotExist = true;

          this.toastr.error('Cannot set email to: ' + this.email, 'Error', { closeButton: true });
          this.isLoading = false;
        })
  }

}
