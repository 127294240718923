import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-splx-modb-ms09',
  templateUrl: './lcon-splx-modb-ms09.component.html',
  styleUrls: ['./lcon-splx-modb-ms09.component.css']
})
export class LconSplxModbMs09Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
