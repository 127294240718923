import { CanonicalService } from './services/canonical/canonical.service';
import { Meta } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LoggedUser } from './models/logged-user';
import { Router, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';
import { CookiesService } from './services/cookies/cookies.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  //public dataIsReady: boolean = false;
  public loggedUser: LoggedUser;

  constructor(private translateService: TranslateService,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private router: Router,
    private authService:AuthenticationService,
    private cookiesService: CookiesService) {
      
      this.authService.currentUser
      .subscribe(
        (loggedUser: LoggedUser) => {
          this.loggedUser = loggedUser;
        });

     }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Lcontrol,Modbus,Firenet,Bacnet,Converter,Converters,Command & control solusions,Products,Raspberry pi,Cyber,Smart buildings,General projects,BMS,Energy saving,Parking management,Trucks management,Work permissions,Events schedule,Counter, Electrical management, Maintenance module,Heat map, Access control,Invite guests,GIS maps,Technologies,Electro mechanical systems,Fire alarm,Energy efficiency,Communications,sales@lcontrol.com, secomea, collaborations,  אלקונטרול , סנייפר, פתרונות שליטה ובקרה, מערכת שליטה ובקרה, סייבר, בניינים חכמים, פרוייקטים, הפרוייקטים שלנו, פרוייקטים כלליים, סנייפר בניינים חכמים, שימור אנרגיה, חיסכון באנרגיה, ניהול חניות, ניהול חניונים, ניהול משאיות, אישורי עבודה, נהלי עבודה, יומן אירועים, מונה, ניהול חשמל, מודול אחזקה, מפות חום, בקרת כניסה, זימון מבקרים, זימון אורחים, שילוב מלא, טכנולוגיות, מערכות אלקטרו מכניות, אזעקת אש, התרעת אש, שימור אנרגיה, ניצול אנרגיה, חסכון באנרגיה, תקשורת, צור קשר, ישראל אשקלון, סיקומאה, אינדוסטריקנט, עקוב אחרינו, שיתופי פעולה, נגישות, נגישות אתרים' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Lcontrol' },
      { name: 'date', content: '2020-01-01', scheme: 'YYYY-MM-DD' }
    ]);

    // on each route change scroll to the top of the page
    this.scrollToTop()

    this.setUserLanguage();
  }

  private scrollToTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private setUserLanguage() {

    let lang = this.cookiesService.getCookie('lang');

    if (!lang) {
      lang = "en";
      this.cookiesService.setCookie("lang", lang, 1);
    }

    this.translateService.use(lang);

    if (lang == "heb") {
      $(document).ready(() => {
        let headId = document.getElementById('rtlStyleSheet');
        $("body").addClass("rtl");
        headId.setAttribute('href', '../../assets/bootstrap-rtl.min.css');
      })
    }
    else {
      $("body").addClass("ltr");
    }
  }

}
