import { ShoppingCart } from './../../models/shopping-cart';
import { ShoppingCartService } from './../../services/shopping-cart/shopping-cart.service';
import { LoggedUser } from './../../models/logged-user';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {

  public loggedUser: LoggedUser;
  public totalItemQuantity: number;


  constructor(public authService: AuthenticationService,
    private router: Router,
    public shoppingCartService: ShoppingCartService) {

    // if the user added or removed a product so update the shopping cart
    this.shoppingCartService.getMessageShoppingCartChanged()
      .subscribe((data: any) => {
        let shoppingCart = this.shoppingCartService.getCart();
        this.totalItemQuantity = 0;
        for (let item of shoppingCart.items)
          this.totalItemQuantity += item.quantity;
      });

    // if the user logged in / out
    this.authService.currentUser
      .subscribe(
        (loggedUser: LoggedUser) => {
          this.loggedUser = loggedUser;
        });
  }

  ngOnInit() {

    this.getShoppingCart();

    this.setAnimationForTopNavbar();

    $(document).ready(() => {
      $(document).scroll(function () {
        var $nav = $(".navbar");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
      });
    });
  }

  private async getShoppingCart() {

    let shoppingCart = await this.shoppingCartService.getShoppingCart();
    this.shoppingCartService.setCart(shoppingCart);
    this.shoppingCartService.sendMessageCartIsReady(true);

    this.totalItemQuantity = 0;
    for (let item of shoppingCart.items)
      this.totalItemQuantity += item.quantity;
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  private setAnimationForTopNavbar() {
    $(document).on("hide.bs.dropdown", "#top-navbar-home .dropdown", function (event) {

      if ($(window).width() > 768) {
        $(event.target).find(">.dropdown-menu:first").slideUp();
      }
      else {
        $(event.target).find(">.dropdown-menu:first ").css("display", "none");
      }
    });

    $(document).on("show.bs.dropdown", "#top-navbar-home .dropdown", function (event) {
      $(event.target).find(">.dropdown-menu:first").slideDown();
    });
  }
}

$(document).on('click', '.dropdown-menu', function (e) {
  e.stopPropagation();
});

// make it as accordion for smaller screens
if ($(window).width() < 992) {
  $('.dropdown-menu a').click(function(e){
    e.preventDefault();
      if($(this).next('.submenu').length){
        $(this).next('.submenu').toggle();
      }
      $('.dropdown').on('hide.bs.dropdown', function () {
     $(this).find('.submenu').hide();
  })
  });
}