import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-modb-bacn-ms01',
  templateUrl: './lcon-modb-bacn-ms01.component.html',
  styleUrls: ['./lcon-modb-bacn-ms01.component.css']
})
export class LconModbBacnMs01Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
