import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-modb-modb-ms04',
  templateUrl: './lcon-modb-modb-ms04.component.html',
  styleUrls: ['./lcon-modb-modb-ms04.component.css']
})
export class LconModbModbMs04Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
