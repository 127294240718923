import { Signup } from './../../models/signup';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  private apiName = "qr-code";

  constructor(private dataService: DataService) { }

  public createQrCode(data: string): Observable<any> {
    this.dataService.apiName = this.apiName + '/create-qr-code';
    return this.dataService.getDataByParam(data);
  }

}
