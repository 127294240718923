import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-firn-bacn-ms06',
  templateUrl: './lcon-firn-bacn-ms06.component.html',
  styleUrls: ['./lcon-firn-bacn-ms06.component.css']
})
export class LconFirnBacnMs06Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
