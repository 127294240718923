import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-tfire-bacn-ms14',
  templateUrl: './lcon-tfire-bacn-ms14.component.html',
  styleUrls: ['./lcon-tfire-bacn-ms14.component.css']
})
export class LconTfireBacnMs14Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
