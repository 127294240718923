import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'contact-button',
  templateUrl: './contact-button.component.html',
  styleUrls: ['./contact-button.component.css']
})
export class ContactButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  public navigateToContact() {
    $(document).ready(() => {
      let elmnt = document.getElementById("contact-us");
      elmnt.scrollIntoView({ behavior: 'smooth'});
    })
  }

}
