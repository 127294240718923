import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }

  // get the name of cookies , value , and time and save it as cookies in the browser
  setCookie(name: string, value: string, expireDays: number, path: string = '/') {
    let d: Date = new Date();

    // these cookies keep live only 1 minutes
    if (expireDays == 0)
      expireDays = 10000 * 6;
    else
      expireDays = expireDays * 24 * 60 * 60 * 1000;


    d.setTime(d.getTime() + expireDays);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  // get the name of cookies and return the value if exist
  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie = function (name, path = '/') {
    this.setCookie(name, '', -1, path);
  }

}
