import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService,
        private router: Router,
        private toastr: ToastrService) { }

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            
            catchError(error => {
                if (error instanceof HttpErrorResponse) {

                    // For unauthorized erros
                    if (error.status === 401) {
                        return throwError(error.statusText);
                    }

                    // Errors that returning from our api server (500 errors)
                    const applicationError = error.headers.get('Application-Error');
                    if (applicationError) {
                        return throwError(applicationError);
                    }

                    // Other erros, that not 500 errors
                    const serverError = error.error;
                    let modalStateErrors = '';
                    if (serverError && typeof serverError === 'object') {
                        for (const key in serverError) {
                            if (serverError[key]) {
                                modalStateErrors += serverError[key] + '\n';
                            }
                        }
                    }

                    // hanlde token expired error
                    if (serverError === 'TokenExpiredError') {
                        this.toastr.error('The token has been expired.', 'Error', { closeButton: true });
                        this.authService.logout();
                        this.router.navigate(['/login']);
                    }

                    return throwError(modalStateErrors || serverError || 'Server Error');
                }
            })
        );

    }

}
