import { Covid19Component } from './components/projects/covid19/covid19.component';
import { SuccessPaymentComponent } from './core/success-payment/success-payment.component';
import { HikvisionMainComponent } from './components/products/hardware/hikvision/hikvision-main/hikvision-main.component';
import { SimplexMainComponent } from './components/products/hardware/simplex/simplex-main/simplex-main.component';
import { TelefireMainComponent } from './components/products/hardware/telefire/telefire-main/telefire-main.component';
import { KnxMainComponent } from './components/products/hardware/knx/knx-main/knx-main.component';
import { DaliMainComponent } from './components/products/hardware/dali/dali-main/dali-main.component';
import { OpcMainComponent } from './components/products/hardware/opc/opc-main/opc-main.component';
import { SnmpMainComponent } from './components/products/hardware/snmp/snmp-main/snmp-main.component';
import { FailedCheckOutComponent } from './core/failed-check-out/failed-check-out.component';
import { SuccessCheckOutComponent } from './core/success-check-out/success-check-out.component';
import { CategoriesComponent } from './components/products/hardware/categories/categories.component';
import { ShoppingCartComponent } from './core/shopping-cart/shopping-cart.component';
import { LoginGuard } from './services/guards/login.guard';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { SignupComponent } from './core/signup/signup.component';
import { LoginComponent } from './core/login/login.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ProgramsComponent } from './components/downloads/programs/programs.component';
import { VideosComponent } from './components/Videos/videos/videos.component';
import { PdfComponent } from './components/downloads/pdf/pdf.component';
import { ControlComponent } from './components/cyber/control/control.component';
import { SecurityComponent } from './components/cyber/security/security.component';
import { SecomeaComponent } from './components/cyber/secomea/secomea.component';
import { SmartBuildingsComponent } from './components/projects/smart-buildings/smart-buildings.component';
import { GeneralProjectsComponent } from './components/projects/general-projects/general-projects.component';
import { HardwareComponent } from './components/products/hardware/hardware.component';
import { SniperComponent } from './components/products/software/sniper/sniper.component';
import { EnergySavingComponent } from './components/products/software/energy-saving/energy-saving.component';
import { ParkingManagmentComponent } from './components/products/software/parking-managment/parking-managment.component';
import { TrucksManagmentComponent } from './components/products/software/trucks-managment/trucks-managment.component';
import { WorkPermitComponent } from './components/products/software/work-permit/work-permit.component';
import { EventScheduleComponent } from './components/products/software/event-schedule/event-schedule.component';
import { CounterComponent } from './components/products/software/counter/counter.component';
import { SoftwareComponent } from './components/products/software/software.component';
import { RealTimeLocationComponent } from "./components/products/software/real-time-location/real-time-location.component";
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { NotAuthorizedComponent } from './core/not-authorized/not-authorized.component';
import { SmartCityComponent } from './components/projects/smart-city/smart-city.component';
import { ModbusMainComponent } from './components/products/hardware/modbus/modbus-main/modbus-main.component';
import { LconModbBacnMs01Component } from './components/products/hardware/modbus/lcon-modb-bacn-ms01/lcon-modb-bacn-ms01.component';
import { LconModbModbMs04Component } from './components/products/hardware/modbus/lcon-modb-modb-ms04/lcon-modb-modb-ms04.component';
import { LconModbOpccMs03Component } from './components/products/hardware/modbus/lcon-modb-opcc-ms03/lcon-modb-opcc-ms03.component';
import { LconModbSnmpMs02Component } from './components/products/hardware/modbus/lcon-modb-snmp-ms02/lcon-modb-snmp-ms02.component';
import { FirenetMainComponent } from './components/products/hardware/firenet/firenet-main/firenet-main.component';
import { LconFirnBacnMs06Component } from './components/products/hardware/firenet/lcon-firn-bacn-ms06/lcon-firn-bacn-ms06.component';
import { LconFirnModbMs05Component } from './components/products/hardware/firenet/lcon-firn-modb-ms05/lcon-firn-modb-ms05.component';
import { LconFirnOpccMs07Component } from './components/products/hardware/firenet/lcon-firn-opcc-ms07/lcon-firn-opcc-ms07.component';
import { LconFirnSnmpMs08Component } from './components/products/hardware/firenet/lcon-firn-snmp-ms08/lcon-firn-snmp-ms08.component';
import { LconSplxBacnMs10Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-bacn-ms10/lcon-splx-bacn-ms10.component';
import { LconSplxModbMs09Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-modb-ms09/lcon-splx-modb-ms09.component';
import { LconSplxOpccMs12Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-opcc-ms12/lcon-splx-opcc-ms12.component';
import { LconSplxSnmpMs11Component } from './components/products/hardware/simplex/simplex-main/lcon-splx-snmp-ms11/lcon-splx-snmp-ms11.component';
import { LconTfireBacnMs14Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-bacn-ms14/lcon-tfire-bacn-ms14.component';
import { LconTfireModbMs13Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-modb-ms13/lcon-tfire-modb-ms13.component';
import { LconTfireOpccMs15Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-opcc-ms15/lcon-tfire-opcc-ms15.component';
import { LconTfireSnmpMs16Component } from './components/products/hardware/telefire/telefire-main/lcon-tfire-snmp-ms16/lcon-tfire-snmp-ms16.component';
import { LconHikvModbMs17Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-modb-ms17/lcon-hikv-modb-ms17.component';
import { LconHikvBacnMs18Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-bacn-ms18/lcon-hikv-bacn-ms18.component';
import { LconHikvModbMs19Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-modb-ms19/lcon-hikv-modb-ms19.component';
import { LconHikvBacnMs20Component } from './components/products/hardware/hikvision/hikvision-main/lcon-hikv-bacn-ms20/lcon-hikv-bacn-ms20.component';
import { BacnetComponent } from './components/products/hardware/bacnet/bacnet.component';
import { OperatingComponent } from './components/downloads/Operating/Operating.component';
import { TemperatureComponent } from './components/products/software/temperature/temperature.component';
import { LconTempModbMs25Component } from './components/products/hardware/modbus/lcon-temp-modb-ms25/lcon-temp-modb-ms25.component';
import { CentersComponent } from './components/products/Centers/Centers.component';
import { SniperEnterpriseComponent } from './components/products/sniper-enterprise/sniper-enterprise.component';
import { VideosTutorialsComponent } from './components/Videos/Videos-Tutorials/Videos-Tutorials.component';
import { QrCodeComponent } from './core/qr-code/qr-code.component';
import { LicensingAgreementComponent } from './components/products/licensing-agreement/licensing-agreement.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'admin', loadChildren: "./admin/admin.module#AdminModule" },
  { path: 'client', loadChildren: "./client/client.module#ClientModule" },
  // { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  // { path: 'shopping-cart', component: ShoppingCartComponent },
  // { path: 'success-check-out', component: SuccessCheckOutComponent },
  // { path: 'success-payment', component: SuccessPaymentComponent },
  { path: 'failed-check-out', component: FailedCheckOutComponent },
  { path: 'about', component: AboutComponent },
  { path: 'programs', component: ProgramsComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'pdf', component: PdfComponent },
  { path: 'Operating-Instructions', component: OperatingComponent },
  { path: 'cyber/control', component: ControlComponent },
  { path: 'cyber/security', component: SecurityComponent },
  { path: 'cyber/Secomea', component: SecomeaComponent },
  { path: 'smart-buildings', component: SmartBuildingsComponent },
  { path: 'general-projects', component: GeneralProjectsComponent },
  { path: 'covid-19', component: Covid19Component },
  { path: 'smart-city', component: SmartCityComponent },
  { path: 'sniper', component: SniperComponent },
  { path: 'energy-saving', component: EnergySavingComponent },
  { path: 'parking-managment', component: ParkingManagmentComponent },
  { path: 'trucks-managment', component: TrucksManagmentComponent },
  { path: 'Work-Permits', component: WorkPermitComponent },
  { path: 'event-schedule', component: EventScheduleComponent },
  { path: 'counter', component: CounterComponent },
  { path: 'software', component: SoftwareComponent },
  { path: 'sniper-enterprise', component: SniperEnterpriseComponent },
  { path: 'centers', component: CentersComponent },
  { path: 'software/sniper', component: SniperComponent },
  { path: 'software/energy-savings', component: EnergySavingComponent },
  { path: 'software/parking-managment', component: ParkingManagmentComponent },
  { path: 'software/trucks-managment', component: TrucksManagmentComponent },
  { path: 'software/work-permit', component: WorkPermitComponent },
  { path: 'software/event-schedule', component: EventScheduleComponent },
  { path: 'software/counter', component: CounterComponent },
  { path: 'software/temperature', component: TemperatureComponent },
  { path: 'software/real-time-location', component: RealTimeLocationComponent },
  { path: 'Videos-Tutorials', component: VideosTutorialsComponent },
  { path: 'licensing-agreement', component: LicensingAgreementComponent },
  
  // {
  //   path: 'hardware',
  //   component: HardwareComponent,
  //   children: [
  //     { path: '', redirectTo: 'categories', pathMatch: 'full' },
  //     { path: 'categories', component: CategoriesComponent },
  //     { path: 'snmp', component: SnmpMainComponent },
  //     { path: 'opc', component: OpcMainComponent },
  //     { path: 'dali', component: DaliMainComponent },
  //     { path: 'knx', component: KnxMainComponent },
  //     { path: 'telefire', component: TelefireMainComponent },
  //     { path: 'simplex', component: SimplexMainComponent },
  //     { path: 'hikvision', component: HikvisionMainComponent },
  //     { path: 'firenet', component: FirenetMainComponent },
  //     { path: 'modbus', component: ModbusMainComponent },
  //     { path: 'bacnet', component: BacnetComponent },
  //     { path: 'lcon-modb-bacn-ms01/:id', component: LconModbBacnMs01Component },
  //     { path: 'lcon-modb-modb-ms04/:id', component: LconModbModbMs04Component },
  //     { path: 'lcon-modb-opcc-ms03/:id', component: LconModbOpccMs03Component },
  //     { path: 'lcon-modb-snmp-ms02/:id', component: LconModbSnmpMs02Component },
  //     { path: 'lcon-firn-bacn-ms06/:id', component: LconFirnBacnMs06Component },
  //     { path: 'lcon-firn-modb-ms05/:id', component: LconFirnModbMs05Component },
  //     { path: 'lcon-firn-opcc-ms07/:id', component: LconFirnOpccMs07Component },
  //     { path: 'lcon-firn-snmp-ms08/:id', component: LconFirnSnmpMs08Component },
  //     { path: 'lcon-splx-bacn-ms10/:id', component: LconSplxBacnMs10Component },
  //     { path: 'lcon-splx-modb-ms09/:id', component: LconSplxModbMs09Component },
  //     { path: 'lcon-splx-opcc-ms12/:id', component: LconSplxOpccMs12Component },
  //     { path: 'lcon-splx-snmp-ms11/:id', component: LconSplxSnmpMs11Component },
  //     { path: 'lcon-tfire-bacn-ms14/:id', component: LconTfireBacnMs14Component },
  //     { path: 'lcon-tfire-modb-ms13/:id', component: LconTfireModbMs13Component },
  //     { path: 'lcon-tfire-opcc-ms15/:id', component: LconTfireOpccMs15Component },
  //     { path: 'lcon-tfire-snmp-ms16/:id', component: LconTfireSnmpMs16Component },
  //     { path: 'lcon-hikv-modb-ms17/:id', component: LconHikvModbMs17Component },
  //     { path: 'lcon-hikv-bacn-ms18/:id', component: LconHikvBacnMs18Component },
  //     { path: 'lcon-hikv-modb-ms19/:id', component: LconHikvModbMs19Component },
  //     { path: 'lcon-hikv-bacn-ms20/:id', component: LconHikvBacnMs20Component },
  //     { path: 'lcon-temp-modb-ms25/:id', component: LconTempModbMs25Component },
      
  //   ]
  // },
  { path: 'qr-code', component: QrCodeComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: '**', redirectTo: '/not-found' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
