import { EnvService } from '../../services/env/env.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public password: string;
  public confirmPassword: string;
  public isLoading: boolean = false;
  public notMatchingPassword: boolean = false;
  private token: string;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private envService: EnvService,
    private router: Router,
    private toastr: ToastrService) {
    this.token = this.route.snapshot.params['token'];
  }

  ngOnInit() {
  }

  public onSubmit() {

    this.isLoading = true;
    this.notMatchingPassword = false;

    let matchingPasswords = this.checkMatchingPasswords();

    if (!matchingPasswords)
      this.notMatchingPassword = true;
    else {

      const httpOptions = {
        headers: new HttpHeaders({
          'x-auth-token': this.token,
          'Content-type': 'application/json'
        })
      }

      this.http.put<any>(`${this.envService.apiUrl}/api/auth/reset-password`, { password: this.password }, httpOptions)
        .subscribe(
          (result: any) => {
            this.toastr.success('Your password has been reset successfully.', 'Success', { closeButton: true });
            this.router.navigate(['/login']);
          },
          (error: Response) => {
            this.toastr.success('Cannot reset password.', 'Error', { closeButton: true });
            this.isLoading = true;
          });
    }
  }

  public checkMatchingPasswords() {

    if (this.password !== this.confirmPassword)
      return false;

    return true;
  }

}
