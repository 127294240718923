import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-city',
  templateUrl: './smart-city.component.html',
  styleUrls: ['./smart-city.component.css']
})
export class SmartCityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
