import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-hikv-bacn-ms18',
  templateUrl: './lcon-hikv-bacn-ms18.component.html',
  styleUrls: ['./lcon-hikv-bacn-ms18.component.css']
})
export class LconHikvBacnMs18Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
