import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: ['./add-to-cart-button.component.css']
})
export class AddToCartButtonComponent implements OnInit {

  public isLoading: boolean = false;

  constructor(private shoppingCartService: ShoppingCartService,
    private toastrService: ToastrService,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  public addToCart() {
    
    this.isLoading = true;
    let id = this.route.snapshot.paramMap.get('id');

    this.shoppingCartService.addProductToShoppingCart(id)
      .then((shoppingCart) => {
        this.isLoading = false;
        this.shoppingCartService.setCart(shoppingCart);
        this.shoppingCartService.sendMessageShoppingCartChanged(shoppingCart);
        this.toastrService.success('Successfullly added product.', 'Success', { closeButton: true });
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastrService.error("Cannot add item to shopping cart", 'Error', { closeButton: true });
      });
  }
}
