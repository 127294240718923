import { DownloadService } from './../../../services/download/download.service';
import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-Operating',
  templateUrl: './Operating.component.html',
  styleUrls: ['./Operating.component.css']
})
export class OperatingComponent implements OnInit {

  public pdfFiles = [
    {
      title: "RISCO PROSYS",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/operating-instruction/pdf/RISCO-PROSYS.pdf"
    },
    {
      title: "RISCO ROKONET",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/operating-instruction/pdf/RISCO-ROKONET.pdf"
    },
    {
      title: "RISCO PROSYS PLUS/LIGHTSYS",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/operating-instruction/pdf/RISCO PROSYS PLUS-LIGHTSYS.pdf"
    },
    {
      title: "PIMA",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/operating-instruction/pdf/PIMA.pdf"
    },
    {
      title: "PIMA 500MWA",
      type: "far fa-file-pdf",
      subTitle: "PDF.Hebrew",
      src: "../../../../assets/pdf/operating-instruction/pdf/PIMA 500MWA.pdf"
    },

  ]

  public videoFiles = [
    {
      title: "ACCESS CONTROL MAIN",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/access-control-main.mp4",
      fileName: "access-control-main.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "OPERATOR + OPERATORS GROUP",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/operator+operators-group.mp4",
      fileName: "operator+operators-group.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "TEMPLATE + SCENARIO",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/template+scenario.mp4",
      fileName: "template+scenario.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "NVR",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/NVR.mp4",
      fileName: "NVR.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "ALARMS",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/alarms.mp4",
      fileName: "alarms.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "SECURITY SYSTEMS",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/Security-Systems.mp4",
      fileName: "Security-Systems.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "DETECTOR DEFINITION",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/detctor-definition.mp4",
      fileName: "detctor-definition.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "CAMERA DEFINITION",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/camera-definition.mp4",
      fileName: "camera-definition.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
    {
      title: "SCREENS BUILDING",
      type: "far fa-file-video",
      subTitle: "PDF.English",
      src: "../../../../assets/video/screens-building.mp4",
      fileName: "screens-building.mp4",
      isDownloading: false,
      precentProgress: 0,
    },
  ]

  constructor(private downloadService: DownloadService) { }

  ngOnInit() {
  }

  download(videoFile: any) {
debugger;
    videoFile.isDownloading = true;

    this.downloadService.downloadVideo(videoFile.fileName)
      .subscribe(
        response => {
          if (response.type === HttpEventType.DownloadProgress) {
            videoFile.precentProgress = Math.round(100 * response.loaded / response.total);
          }
          if (response.type === HttpEventType.Response) {

            setTimeout(() => {
              videoFile.precentProgress = 0;
              videoFile.isDownloading = false;
            }, 800);

            const blob: Blob = new Blob([response.body], { type: response.body.type });
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = videoFile.fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);

          }
        },
        (error: any) => {
          videoFile.precentProgress = 0;
          videoFile.isDownloading = false;
        })
  }

}
