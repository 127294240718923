export class Product {

    public _id: string;
    public name: string;
    public categories: string[] = [];
    public image: any;
    public description: string;
    public newPrice?: number;
    public quantity?: number;

    constructor() { }

}
