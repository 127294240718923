import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Videos-Tutorials',
  templateUrl: './Videos-Tutorials.component.html',
  styleUrls: ['./Videos-Tutorials.component.css']
})
export class VideosTutorialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
