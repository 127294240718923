import { Component, OnInit, AfterViewInit } from '@angular/core';
import {Location} from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-energy-saving',
  templateUrl: './energy-saving.component.html',
  styleUrls: ['./energy-saving.component.css']
})
export class EnergySavingComponent implements OnInit, AfterViewInit {

  constructor(private location: Location) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    // slide the content 
    $(window).scroll(function () {
      $(".slideanim").each(function () {
        var pos = $(this).offset().top;
        var winTop = $(window).scrollTop();
        if (pos < winTop + 680) {
          $(this).addClass("slide");
        }
      });
    });

  }

  public back(){
    this.location.back();
  }
}
