import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { ProductService } from 'src/app/services/product/product.service';
import { ShoppingCart } from 'src/app/models/shopping-cart';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-dali-main',
  templateUrl: './dali-main.component.html',
  styleUrls: ['./dali-main.component.css']
})
export class DaliMainComponent implements OnInit {
  public products: Product[] = [];
  public errorMsg: string;
  public shoppingCart: ShoppingCart;

  constructor(private productService: ProductService,
    private shoppingCartService: ShoppingCartService) {

    this.shoppingCartService.getMessageCartIsReady()
      .subscribe((data) => {
        this.shoppingCart = this.shoppingCartService.getCart();
      })
  }

  ngOnInit() {
    this.shoppingCart = this.shoppingCartService.getCart();
    this.products = this.productService.getLocalProductsByProtocolCategroy("Dali");
  }
}
