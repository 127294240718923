import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-hikv-modb-ms19',
  templateUrl: './lcon-hikv-modb-ms19.component.html',
  styleUrls: ['./lcon-hikv-modb-ms19.component.css']
})
export class LconHikvModbMs19Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
