import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-splx-bacn-ms10',
  templateUrl: './lcon-splx-bacn-ms10.component.html',
  styleUrls: ['./lcon-splx-bacn-ms10.component.css']
})
export class LconSplxBacnMs10Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
