import { Component, OnInit } from '@angular/core';
import { CookiesService } from '../../../services/cookies/cookies.service';

@Component({
  selector: 'app-general-projects',
  templateUrl: './general-projects.component.html',
  styleUrls: ['./general-projects.component.css']
})
export class GeneralProjectsComponent implements OnInit {

  private modal: any;

  private currentLang: string = "en";

  constructor(private cookiesService: CookiesService) { }

  ngOnInit() {
  }

  public openImageModal(num: number) {

    this.modal = document.getElementById('myModal');
    let modalImg = <HTMLImageElement>document.getElementById("img01");
    this.modal.style.display = "block";

    this.currentLang = this.cookiesService.getCookie('lang');

    switch (num) {
      case 1:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/ichilov-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/ichilov-en.png";
          break;
        }
      case 2:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/malam-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/malam-en.png";
          break;
        }
      case 3:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/terminal-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/terminal-en.png";
          break;
        }
      case 4:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/rtls-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/rtls-en.png";
          break;
        }
      case 5:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/alex-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/alex-en.png";
          break;
        }
      case 6:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/sammy-ofer-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/sammy-ofer-en.png";
          break;
        }
      case 7:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/ben-gurion-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/ben-gurion-en.png";
          break;
        }
      case 8:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/discount-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/discount-en.png";
          break;
        }
      case 9:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/eintal-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/eintal-en.png";
          break;
        }
      case 10:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/bursa-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/bursa-en.png";
          break;
        }
      case 11:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/heichal-shlomo-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/heichal-shlomo-en.png";
          break;
        }
      case 12:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/herzog-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/herzog-en.png";
          break;
        }
      case 13:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/blades-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/blades-en.png";
          break;
        }
      case 14:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/depo-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/depo-en.png";
          break;
        }
      case 15:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/waldorf-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/waldorf-en.png";
          break;
        }
      case 16:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/mekorot-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/mekorot-en.png";
          break;
        }
      case 17:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/intergreen-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/intergreen-en.png";
          break;
        }
      case 18:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/bloomfield-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/bloomfield-en.png";
          break;
        }
      case 19:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/golbary-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/golbary-en.png";
          break;
        }
      case 20:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/bar-kokhba-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/bar-kokhba-en.png";
          break;
        }
      case 21:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/togethet-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/togethet-en.png";
          break;
        }
      case 22:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/bar-lev-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/bar-lev-en.png";
          break;
        }
      case 23:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/azrieli-lehavim-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/azrieli-lehavim-en.png";
          break;
        }
      case 24:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/azrieli-modiin-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/azrieli-modiin-en.png";
          break;
        }
      case 25:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/ikea-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/ikea-en.png";
          break;
        }
      case 26:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/menora-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/menora-en.png";
          break;
        }
      case 27:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/cqm-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/cqm-en.png";
          break;
        }
      case 28:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/luxemburg-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/luxemburg-en.png";
          break;
        }
      case 29:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/rtls-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/rtls-en.png";
          break;
        }
      case 30:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/brinks-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/brinks-en.png";
          break;
        }
      case 31:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/isrotel-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/isrotel-en.png";
          break;
        }
      case 32:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/el-al-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/el-al-en.png";
          break;
        }
      case 33:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/tel-ashomer-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/tel-ashomer-en.png";
          break;
        }
      case 34:
        {
          if (this.currentLang == "heb")
            modalImg.src = "../../../../assets/image/general-projects/heb/defense-he.png";
          else
            modalImg.src = "../../../../assets/image/general-projects/en/defense-en.png";
          break;
        }
    }
  }

  public closeImageModal() {
    this.modal.style.display = "none";
  }

}
