import { Signup } from '../../models/signup';
import { EnvService } from '../env/env.service';
import { LoggedUser } from '../../models/logged-user';
import { Credentials } from '../../models/credentials';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtService } from '../jwt/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: Observable<LoggedUser>;
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  private tempUser: LoggedUser;
  private tempToken: string;

  constructor(private http: HttpClient,
    private envService: EnvService,
    private jwtService: JwtService) {
    let token = this.jwtService.decodeToken();
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(token);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public login(credentials: Credentials) {

    return this.http.post<LoggedUser>(`${this.envService.apiUrl}/api/auth`, credentials, { observe: 'response' })
      .pipe(map(
        (response: HttpResponse<LoggedUser>) => {

          let token = response.headers.get('x-auth-token');

          if (response.body && token) {
            this.saveUser(response.body, token);
            return true;
          }
          else
            return false;
        }));
  }

  public loginWithGoogleOrFb(email: string) {

    return this.http.post(`${this.envService.apiUrl}/api/auth/with-google-or-facebook`, { email: email }, { observe: 'response' })
      .pipe(map(
        (response: HttpResponse<LoggedUser>) => {

          let token = response.headers.get('x-auth-token');

          if (response.body && token) {
            this.saveUser(response.body, token);
            return true;
          }
          else
            return false;
        }));
  }

  public signup(signup: Signup) {

    return this.http.post<LoggedUser>(`${this.envService.apiUrl}/api/auth/signup`, signup, { observe: 'response' })
      .pipe(map(
        (response: HttpResponse<LoggedUser>) => {

          let token = response.headers.get('x-auth-token');

          if (response.body && token) {
            this.jwtService.setToken(token);
            this.currentUserSubject.next(response.body);
            return true;
          }
          else
            return false;
        }));
  }

  public updateMe(user) {

    return this.http.put<LoggedUser>(`${this.envService.apiUrl}/api/auth/update-me/` + user._id, user, { observe: 'response' })
      .pipe(map(
        (response: HttpResponse<LoggedUser>) => {

          let token = response.headers.get('x-auth-token');

          if (response.body && token) {
            this.jwtService.setToken(token);
            this.currentUserSubject.next(response.body);
            return true;
          }
          else
            return false;
        }));
  }

  public get currentLoggedUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }

  public getTempToken(): string {
    return this.tempToken;
  }

  public getTempUser(): LoggedUser {
    return this.tempUser;
  }

  public resetTempUserToken() {
    this.tempToken = null;
  }

  public resetTempUser() {
    this.tempToken = null;
  }

  public logout() {
    this.jwtService.removeToken();
    this.currentUserSubject.next(null);
  }

  public saveUser(user: LoggedUser, token: string) {
    this.jwtService.setToken(token);
    this.currentUserSubject.next(user);
  }

}
