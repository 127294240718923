import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-hikv-bacn-ms20',
  templateUrl: './lcon-hikv-bacn-ms20.component.html',
  styleUrls: ['./lcon-hikv-bacn-ms20.component.css']
})
export class LconHikvBacnMs20Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
