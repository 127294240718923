import { EnvService } from './../env/env.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl: string;
  public apiName: string;

  constructor(private http: HttpClient,
    private envService: EnvService) {
    this.apiUrl = this.envService.apiUrl;
  }

  public getData(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/' + this.apiName).pipe(
      map(
        (response: any) => {
          return response;
        }));
  }

  public getDataByParam(paramValue: string | number): Observable<any> {

    return this.http.get(this.apiUrl + '/api/' + this.apiName + '/' + paramValue).pipe(
      map(
        (response: any) => {
          return response;
        }));
  }

  public getDataByObject(data: any): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(this.apiUrl + '/api/' + this.apiName, JSON.stringify(data), { headers: headers }).pipe(
      map(
        (response: any) => {
          return response;
        }));
  }

  public add(data: any): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(this.apiUrl + '/api/' + this.apiName, JSON.stringify(data), { headers: headers }).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  public update(paramValue: string | number, data: any): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.put(this.apiUrl + '/api/' + this.apiName + '/' + paramValue, JSON.stringify(data), { headers: headers }).pipe(
      map(
        (response: Response) => {
          return response
        }));
  }

  public delete(paramValue: string | number): Observable<any> {

    return this.http.delete(this.apiUrl + '/api/' + this.apiName + '/' + paramValue).pipe(
      map(
        (response: Response) => {
          return response
        }));
  }

  public getFile(fileName: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.apiUrl + '/api/' + this.apiName + '/' + fileName, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(
      map(
        (response: any) => {
          return response;
        }));
  }


  public getApiName(): string {
    return this.apiName;
  }

}
