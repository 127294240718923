import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class AccessibilityService {

    private toggleAccessibilityMenu: boolean = false;
    private currentFontSize: number = 140;
    private currentContrast: string = "default";
    private currentLang: string;

    constructor(private translateService: TranslateService) { }

    public openOrCloseAccessibilityMenu() {

        let currentLang = this.translateService.currentLang;
        if (!this.toggleAccessibilityMenu) {
            if (currentLang == 'he') {
                $(document).ready(function () {
                    $("#font-size").css({ "left": "-4px" });
                    $("#color-contrast").animate({ left: '-4px' }, 100);
                    $("#links").animate({ left: '-4px' }, 150);
                    $("#readable-font").animate({ left: '-4px' }, 200);
                    $("#reset-settings").animate({ left: '-4px' }, 250);
                });
            }
            else {
                $(document).ready(function () {
                    $("#font-size").css({ "right": "-4px" });
                    $("#color-contrast").animate({ right: '-4px' }, 100)
                    $("#links").animate({ right: '-4px' }, 150);
                    $("#readable-font").animate({ right: '-4px' }, 200);
                    $("#reset-settings").animate({ right: '-4px' }, 250);
                });
            }
        }
        else {
            if (currentLang == 'he') {
                $(document).ready(function () {
                    $("#font-size").css({ "left": "-260px" });
                    $("#color-contrast").animate({ left: '-260px' }, 100)
                    $("#links").animate({ left: '-260px' }, 150);
                    $("#readable-font").animate({ left: '-260px' }, 200);
                    $("#reset-settings").animate({ left: '-260px' }, 250);
                });
            }
            else {
                $(document).ready(function () {
                    $("#font-size").css({ "right": "-260px" });
                    $("#color-contrast").animate({ right: '-260px' }, 100)
                    $("#links").animate({ right: '-260px' }, 150);
                    $("#readable-font").animate({ right: '-260px' }, 200);
                    $("#reset-settings").animate({ right: '-260px' }, 250);
                });
            }
        }

        this.toggleAccessibilityMenu = !this.toggleAccessibilityMenu;
    }

    reset() {
        this.currentFontSize = 140;
        document.body.style.fontSize = 140 + "%";

        this.currentContrast = "default";
        document.body.style.background = "white";
    }

    highOrLowContrast(action: string) {

        if (action == 'high')
            document.body.style.background = "black";
        else
            document.body.style.background = "white";
    }

    increaseOrDecreaseFontSize(action: string) {
        var num = 10;

        if (action == "increase") {
            if (this.currentFontSize <= 190) {
                document.body.style.fontSize = this.currentFontSize + num + "%";
                this.currentFontSize += num;
            }
        }
        else {
            if (this.currentFontSize > 140) {
                document.body.style.fontSize = this.currentFontSize - num + "%";
                this.currentFontSize -= num;
            }
        }
    }


}
