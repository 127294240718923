import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-modb-opcc-ms03',
  templateUrl: './lcon-modb-opcc-ms03.component.html',
  styleUrls: ['./lcon-modb-opcc-ms03.component.css']
})
export class LconModbOpccMs03Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
