import { CountreisService } from './../../services/countries/countreis.service';
import { VisitorsService } from './../../services/visitor/visitor.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Signup } from 'src/app/models/signup';
import { forkJoin } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public signup: Signup = new Signup();
  public isLoading: boolean = false;
  public inavlidInput: string;
  public userExist: boolean = false;
  public returnUrl: string;
  public countries: any[];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private visitorsService: VisitorsService,
    private countreisService: CountreisService,
    private authService: AuthenticationService) { }

  ngOnInit() {

    const requestArray = [];
    requestArray.push(this.countreisService.getCountries());
    requestArray.push(this.visitorsService.getVisitorDetails());

    forkJoin(requestArray)
      .subscribe(
        (response: any[]) => {
          this.countries = response[0];
          let visitor = response[1];

          if (visitor) {
            for (const country of this.countries) {
              if (visitor.country === country.code) {
                this.signup.country = country;
                break;
              }
            }
          }
          else
            this.signup.country = this.countries[0];

        },
        (error) => {

          this.countreisService.getCountries()
            .subscribe(
              (countries) => {
                this.countries = countries;
                this.signup.country = this.countries[0];
              })
        });


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/client';
  }

  public checkPassword() {
    if (this.signup.password && this.signup.password.length >= 4)
      $('#password').removeClass('is-invalid');
    else
      $('#password').addClass('is-invalid');
  }

  public checkEmail() {
    if (this.signup.email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let result = re.test(String(this.signup.email).toLowerCase());
      if (result)
        $('#email').removeClass('is-invalid');
      else
        $('#email').addClass('is-invalid');
    }
    else
      $('#email').addClass('is-invalid');
  }

  public signupUser() {

    this.isLoading = true;
    this.userExist = false;
    this.inavlidInput = null;

    this.authService.signup(this.signup)
      .subscribe(
        (result: any) => {

          // login successful so redirect to return url
          if (result)
            this.router.navigateByUrl(this.returnUrl);
          else
            this.toastrService.error('The user name or password is incorrect.', 'Error');

          this.isLoading = false;
        },
        (error: string) => {

          if (error === "User already registered")
            this.userExist = true;
          else
            this.inavlidInput = error;

          this.isLoading = false;
        })
  }

}
