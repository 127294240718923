import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountreisService {

  constructor(private http: HttpClient) { }

  public getCountries(): Observable<any[]> {
    return this.http.get<any[]>("./assets/countries/countries.json");
  }

}
