import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcon-tfire-modb-ms13',
  templateUrl: './lcon-tfire-modb-ms13.component.html',
  styleUrls: ['./lcon-tfire-modb-ms13.component.css']
})
export class LconTfireModbMs13Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
